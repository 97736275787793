import {
    watchGetUserDataCall,
    watchLoginCall,
    watchRegisterCall,
    watchResetPasswordCall,
    watchResetPasswordConfirmCall,
    watchSendSupportCall,
    watchUpdatePasswordCall
} from "./userSaga";
import {all} from "redux-saga/effects";
import {
    watchCountTaskResource,
    watchCreateResource,
    watchDeleteResource,
    watchGetHistoryResource,
    watchGetResource,
    watchGetResourceDetails,
    watchGetResourceSilent,
    watchGrabTaskResource,
    watchUpdateMultipleResource,
    watchUpdateResource,
} from './resourceSaga'

import {
    watchCloseTaskDialog,
    watchGetCommunicationList,
    watchGetConversation,
    watchGetNotificationList,
    watchGlobalInfoSearchPopupDialog,
    watchGlobalSearchPopupDialog,
    watchGlobalSearchShow,
    watchHideChatSidebar,
    watchHideConfirmDialog,
    watchHideConfirmRedirectionDialog,
    watchHideCreateOfficeDialog,
    watchHideHelpSidebar,
    watchHideHistoryModal,
    watchHideInfoDialog,
    watchHideMapDialog,
    watchHideModal,
    watchHideResourceDialog,
    watchHideSecondModal,
    watchHideUpdateDialog,
    watchPushNotification,
    watchSetupNewLoadDialog,
    watchSetupNewLoadDialogSeccond,
    watchUpdateChartWidth,
} from './ui'
import {watchDeleteDocument, watchDownload, watchPreviewDocument, watchUploadDocument} from "./download";
import {watchGetStackedResource} from "./stackedResourceSaga";
import {watchGetHelpResource, watchUpdateHelpResource} from './helpResourceSaga'
import {
    watchCreateSecondResource,
    watchDeleteSecondResource,
    watchGetSecondResource,
    watchUpdateSecondResource,
} from "./secondResource";
import {watchCreateCommentResource, watchDeleteCommentResource, watchUpdateCommentResource} from "./commentResource";
import {
    watchCreateSidebarResource,
    watchGetInfiniteSidebarResource,
    watchGetSidebarResource,
    watchUpdateSidebarResource
} from "./sidebarResource";
import {watchGetContactResource} from "./contactsSaga";

import {
    watchCreateIntegrationResource,
    watchDeleteIntegrationResource,
    watchGetIntegrationResource,
    watchUpdateIntegrationResource
} from "./integrationResourceSaga";
import {watchCreatePublicResource, watchGetPublicResource, watchUpdatePublicResource} from "./publicSaga";
import {
    watchCreateDialogResource,
    watchDeleteDialogResource,
    watchGetDialogResource,
    watchGetDialogResourceDetails,
    watchUpdateDialogResource,
    watchUploadDialogDocument
} from "./dialogResourceSaga";
import {watchCreateTaskResource, watchGetTaskResource, watchUpdateTaskResource} from "./taskResourceSaga";
import {watchCreateContactResource, watchDeleteContactResource, watchUpdateContactResource} from "./contactSaga";

import {watchUploadOcrFillDocument} from "./ocrFillSaga";

import {watchDeleteDialogInfoResource, watchGetDialogInfoResource} from "./dialogInfoSaga";
import {
    watchCreateThirdResource,
    watchDeleteThirdResource,
    watchGetThirdResource,
    watchUpdateThirdResource
} from './thirdResourceSaga'
import {
    watchCreateResourceDiff,
    watchDeleteResourceDiff,
    watchGetResourceDiff,
    watchUpdateResourceDiff
} from "./resourceDiffSaga";
import {
    watchCreateDocumentResource,
    watchDeleteDocumentResource,
    watchGetDocumentResource,
    watchUpdateDocumentResource
} from "./documentResource";
import {
    watchCreateAccountingResource,
    watchDeleteAccountingResource,
    watchGetAccountingResource
} from "./accountingResource";
import {
    watchCreateAccountingInfoResource,
    watchDeleteAccountingInfoResource,
    watchGetAccountingInfoResource
} from "./accountingInfoResource";

/*
 * Single entry point to start all Sagas at once
 =========================================================================================*/
export default function* rootSaga() {
    yield all([
        watchLoginCall(),
        watchGetUserDataCall(),
        watchRegisterCall(),
        watchResetPasswordCall(),
        watchResetPasswordConfirmCall(),
        watchUpdatePasswordCall(),
        watchSendSupportCall(),
        watchHideCreateOfficeDialog(),
        watchHideConfirmDialog(),
        watchHideUpdateDialog(),
        watchHideInfoDialog(),
        watchHideMapDialog(),
        watchCloseTaskDialog(),
        watchGlobalSearchPopupDialog(),
        watchGlobalInfoSearchPopupDialog(),
        watchGlobalSearchShow(),
        watchGetResource(),
        watchGetPublicResource(),
        watchUpdatePublicResource(),
        watchCreatePublicResource(),
        watchGetIntegrationResource(),
        watchCreateIntegrationResource(),
        watchUpdateIntegrationResource(),
        watchDeleteIntegrationResource(),

        watchGetSecondResource(),
        watchGetThirdResource(),
        watchGetTaskResource(),
        watchSetupNewLoadDialog(),
        watchSetupNewLoadDialogSeccond(),
        watchGetResourceDetails(),
        watchGetResourceSilent(),

        watchCreateResourceDiff(),
        watchGetResourceDiff(),
        watchDeleteResourceDiff(),
        watchUpdateResourceDiff(),

        watchGrabTaskResource(),
        watchGetHistoryResource(),
        watchHideChatSidebar(),
        watchHideHelpSidebar(),
        watchHideConfirmRedirectionDialog(),
        watchCountTaskResource(),

        watchCreateTaskResource(),
        watchUpdateTaskResource(),

        watchCreateThirdResource(),
        watchUpdateThirdResource(),
        watchDeleteThirdResource(),

        watchHideResourceDialog(),

        watchCreateResource(),
        watchUpdateResource(),
        watchUpdateMultipleResource(),
        watchDeleteResource(),

        watchDownload(),
        watchUploadDocument(),
        watchDeleteDocument(),
        watchPreviewDocument(),

        watchGetSidebarResource(),
        watchGetInfiniteSidebarResource(),
        watchCreateSidebarResource(),
        watchUpdateSidebarResource(),

        watchUpdateChartWidth(),

        watchGetStackedResource(),
        watchGetNotificationList(),
        watchGetConversation(),
        watchGetCommunicationList(),
        watchGetContactResource(),

        watchHideModal(),
        watchHideHistoryModal(),
        watchHideSecondModal(),

        watchUpdateHelpResource(),
        watchGetHelpResource(),
        watchCreateSecondResource(),
        watchDeleteSecondResource(),
        watchUpdateSecondResource(),

        watchCreateCommentResource(),
        watchDeleteCommentResource(),
        watchUpdateCommentResource(),

        watchUpdateContactResource(),
        watchCreateContactResource(),
        watchDeleteContactResource(),

        watchPushNotification(),

        // Autofill
        watchUploadOcrFillDocument(),

        // Document resource - for getting document lists and uploading and updating document metadata
        watchGetDocumentResource(),
        watchCreateDocumentResource(),
        watchDeleteDocumentResource(),
        watchUpdateDocumentResource(),

        // Resource dialog
        watchGetDialogResource(),
        watchGetDialogResourceDetails(),
        watchCreateDialogResource(),
        watchUpdateDialogResource(),
        watchDeleteDialogResource(),
        watchUploadDialogDocument(),

        watchGetDialogInfoResource(),
        watchDeleteDialogInfoResource(),

        // Accounting resource
        watchGetAccountingResource(),
        watchGetAccountingInfoResource(),
        watchCreateAccountingResource(),
        watchCreateAccountingInfoResource(),
        watchDeleteAccountingResource(),
        watchDeleteAccountingInfoResource()
    ]);
}
