import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import {showModal} from '../../../data/actions/ui'
import LocalStorage from '../../../common/util/localStorage'
import Resources from '../../../data/services/resources'
import {download} from '../../../data/actions/download'
import {Field, FieldsManager} from '../../../data/services/fields'
import IncomeExpenseDialog from '../dialogs/income-expense-dialog'
import PaymentDialog from '../dialogs/payment-dialog/payment-dialog'
import moment from 'moment-timezone'
import {
    ArchiveBoxArrowDownIcon,
    CheckIcon,
    CreditCardIcon,
    CurrencyDollarIcon,
    DocumentDuplicateIcon,
    EyeIcon,
    PencilIcon,
    TicketIcon,
    UsersIcon
} from '@heroicons/react/24/outline'
import Tippy from '@tippyjs/react'
import {
    AdjustmentsHorizontalIcon,
    ChevronUpIcon as ChevronUpSolidIcon,
    DocumentArrowDownIcon,
    XMarkIcon
} from '@heroicons/react/20/solid'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_HISTORY_TABLE_FILTER_FIELDS,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DEFAULT_QUERY_LIMIT,
    DELETE_PERM,
    MAJOR_ACCOUNT_TYPE_EXPENSE,
    MAJOR_ACCOUNT_TYPE_LIABILITY,
    READ_PERM,
    UPDATE_PERM
} from "../../../common/util/util-consts";
import {
    checkPerm,
    classNames,
    getDefaultFieldOrder,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getLookup,
    getProp,
    longTableColumn,
    mergeDeep,
    renderExpenseStatusBadge,
    resourceIsCreated,
    resourceIsDeleted,
    returnOffsetAndPagination,
    saveTableColumns,
    verifyFilterFields
} from '../../../common/util/util-helpers'
import {currentDate, toFrontDateTimeFromUTC} from '../../../common/util/util-dates'
import {cloneDeep, genericMoneyFormatter} from '../../../common/util/util-vanilla'
import TrashIcon from '@heroicons/react/24/outline/TrashIcon'
import InvoiceExpenseDetailsDialog from '../dialogs/invoice-expense-details-dialog'
import {createSecondResource, deleteSecondResource, getSecondResource} from '../../../data/actions/secondResource'
import {getThirdResource} from '../../../data/actions/thirdResource'
import CheckCircleIcon from '@heroicons/react/20/solid/CheckCircleIcon'
import PayMultipleBillsDialog from '../dialogs/pay-multiple-bills-dialog'

import PayDateFilter from '../carrier-pay/carrier-pay-date-filter'
import FireSolidIcon from '@heroicons/react/20/solid/FireIcon'
import FireIcon from '@heroicons/react/24/outline/FireIcon'
import {getHistoryResource} from '../../../data/actions/historyResource'
import {HourglassIcon} from '../../../data/themes/icons'
import BulkPaymentDialog from '../dialogs/bulk-payment-dialog'
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import ResourceTable from "../../../common/components/resource-table";
import {excludeFields, fillFieldsFromData} from "../../../common/util/util-fields";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import HistoryModal, {DEFAULT_HISTORY_TABLE_FIELDS} from "../../../common/components/modal/modal-history";
import ResourceTableTags from "../../../common/components/resource-table/table-components/resource-table-tags";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import TableBulkActions from "../../../common/components/resource-table/table-components/table-bulk-actions";
import TableTagManager from "../../../common/components/resource-table/table-components/table-tag-manager";
import {Flags} from "../../../common/components/feature-flags";
import NoRecords from "../../../common/components/no-records-found/no-records";
import Modal from "../../../common/components/modal";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import ModalDefault from "../../../common/components/modal/modal-default";
import ContactInfoDialog from "../../../common/components/modal/contact-info-dialog";
import OrganizationInfoDialog from "../../../common/components/modal/modal-global/organization-info-dialog";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import Button from '../../../common/components/button'
import {numberWithCommasToBack} from "../../../util/util-formaters";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import {getDialogResource, resetDialogResource} from "../../../data/actions/dialogResource";
import FieldDate from "../../../common/components/fields/field-date";
import FieldContainer from "../../../common/components/fields/field-container";

class ExpenseTab extends Component {

    constructor(props) {
        super(props)
        this.colors = ['#171219', '#225560', '#E9DF00', '#F0803C', '#FF66B3', '#084B83', '#42BFDD', '#72BDA3', '#03FCBA', '#770058']
        this.pagePath = this.props.location.pathname.substring(1) + '_expenses'

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: true
            },
            columns: {
                AutoCounter: {
                    minWidth: 85,
                },
                Labels: {minWidth: 100},
                RefNumber: {minWidth: 105},
                Amount: {minWidth: 120},
                AmountTransferred: {minWidth: 120},
                AmountAdvance: {minWidth: 120},
                AmountAdvanceTransferred: {minWidth: 120},
                IsPayrollExpense: {minWidth: 50},
                ExpenseTypeID: {minWidth: 80},
                Date: {minWidth: 130},
                DueDate: {minWidth: 130},
                BookDate: {minWidth: 130},
                ExpenseStatusID: {minWidth: 100},
                IsApproved: {minWidth: 80},
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            // Default state
            ...DEFAULT_CRUD_STATE,
            sortBy: 'ExpenseID',
            sort: 'DESC',
            limit: 100,

            // Fields
            tableFields: this.getTableFields(),
            fieldsOrder: getDefaultFieldOrder(this.getTableFields(), this.pagePath),
            tableOptions: getDefaultTableOptions(this.getTableFields(), this.tablePageDefaults, this.pagePath, this.props.translate),
            fieldsApplyCreditDate: this.getApplyCreditDateFields(),

            // Filter, and header fields
            queryFilterFields: this.getQueryFilterFields(),

            selectedItems: {},
            selectedRows: {},

            // Dialog(s)
            isInvoiceDialogVisible: false,
            incomeExpenseDialog: false,
            isExpenseCopyDialogVisible: false,
            deleteDialog: false,
            approveDialog: false,
            isLimitBelowRequiredDialogOpen: false,

            isTableFullScreen: false,

            batchActionDialog: false,
            batchActionDialogHideAnimation: false,

            isSendPaymentDialogVisible: false,
            isApplyCreditDialogVisible: false,
            showConfirm: false
        }

        this.PopoverButtonRef = React.createRef()

        this.state = mergeDeep(this.state, verifyFilterFields(
            LocalStorage.rehydrateState(this.pagePath),
            this.state.queryFilterFields
        ))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
        this.fetchLabels()
    }

    componentDidUpdate(prevProps) {
        if (resourceIsDeleted(this.props.secondResource, prevProps.secondResource)) {
            this.fetchInvoiceDialogData()
            this.fetchData()
        }

        if (prevProps.breakpoint !== this.props.breakpoint && this.props.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
            this.handleFilterInputChange('limit', 10)
        }

        if (resourceIsCreated(this.props.resource, prevProps.resource) && this.props.resource.resource === 'accounting/expense/payment') {
            if (this.state.isDetailsDialogVisible) {
                this.fetchInvoiceDialogData();
            }
        }
    }

    /** Data Events
     ================================================================= */

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResourceName(),
            query: this.getQuery()
        }))
    }

    fetchLabels = () => {
        this.props.dispatch(getThirdResource({
            user: LocalStorage.get('user'),
            resource: Resources.ColorTag
        }))
    }

    fetchApplyCredits = (item) => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            resource: Resources.ExpenseVendorCredit,
            query: {
                searchFields: JSON.stringify({
                    VendorCreditStatusID: ["VendorCreditStatusID", "=", 0],
                    OrganizationID: item?.OrganizationID
                })
            }
        }))
    }

    handleSetMarkedClick = (isMarkAction = true) => {
        const selectedRows = this.state.selectedRows

        const ExpenseIDs = Object.keys(selectedRows)

        this.props.dispatch(createResource({
            user: LocalStorage.get('user'),
            params: {
                InvoiceIDs: [],
                ExpenseIDs: ExpenseIDs,
                Marked: isMarkAction ? 1 : 0
            },
            query: this.getQuery(),
            resource: Resources.AccountingMark,
            piggyResource: this.getResourceName(),
            errorMessage: true,
            successMessage: isMarkAction ? this.props.translate('message.selection_add_marked') : this.props.translate('message.selection_remove_marked')
        }))

        this.setState({selectedRows: {}})
    }

    onBulkDownloadExpenses = () => {
        this.props.dispatch(createResource({
            user: LocalStorage.get('user'),
            resource: Resources.ExpenseDocumentsPDF,
            piggyResource: this.getResourceName(),
            params: {
                ExpenseIDs: Object.keys(this.state.selectedRows)
            },
            successMessage: 'JOB_STARTED'
        }))

        this.setState({selectedRows: {}})
    }

    fetchInvoiceDialogData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            query: {
                ExpenseID: this.state.selectedItem.ExpenseID
            },
            resource: Resources.ExpenseExpense
        }))
    }

    deleteResource = (item) => {
        this.handleToggleDeleteDialog(() => {
            this.setState({
                ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
            }, () => {
                this.props.dispatch(deleteResource({
                    user: LocalStorage.get('user'),
                    query: Object.assign({}, this.getQuery(), {
                        ExpenseID: item.ExpenseID
                    }),
                    errorMessage: true, successMessage: `${item.AutoCounter} deleted.`,
                    resource: Resources.Expense,
                    piggyResource: Resources.Expense
                }))
                this.handleToggleDeleteDialog()
                if (this.state.isDetailsDialogVisible) {
                    this.handleToggleShowDetails();
                }
                const rows = Object.values(Object.assign({}, this.state.selectedRows)).reduce((memo, it) => {
                    if (it.ExpenseID !== item.ExpenseID) {
                        memo[it.ExpenseID] = it
                    }
                    return memo
                }, {})
                this.setSelectedRows(rows)
            })
        }, item)
    }

    approveResource = (item) => {
        const contactApprovalLimit = getProp(this.props.resource.data, 'Contact.MaxExpenseValue', 0)
        const expenseAmount = item.Amount

        if (contactApprovalLimit >= expenseAmount) {
            this.handleToggleApproveDialog(() => {
                this.props.dispatch(updateResource({
                    user: LocalStorage.get('user'),
                    params: {id: item.ExpenseID},
                    errorMessage: true, successMessage: `${item.ExpenseID} approved.`,
                    resource: Resources.ExpenseApprove,
                    piggyResource: Resources.Expense,
                    query: this.getQuery()
                }))
                this.setState({showConfirm: !this.state.showConfirm})
            })
        } else {
            this.toggleLimitBelowRequiredDialog()
        }
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.Expense,
            query: Object.assign(
                {format: 'EXCEL', name: 'expenses_expenses' + currentDate() + '.xlsx'},
                this.getQuery()
            )
        }))
    }


    submitBulkPayment = (fields, payments) => {
        this.props.dispatch(createResource({
            user: LocalStorage.get('user'),
            params: Object.assign({}, FieldsManager.getFieldKeyValues(fields), {
                morePayments: payments.map(item => {
                    return {
                        ExpenseID: item.ExpenseID,
                        Amount: Number(item.EnteredAmount).toFixed(2),
                    }
                })
            }),
            resource: Resources.ExpensePayment,
            piggyResource: this.getResourceName(),
            query: this.getQuery(),
            errorMessage: true,
            successMessage: this.props.translate('text.multiple_bills_paid')
        }))
        this.setState({selectedItems: {}, selectedRows: {}})
    }

    /** Fields definitions
     ================================================================= */
    getTableFields = () => {
        return {
            AutoCounter: new Field('AutoCounter', '', [], false, 'text', {
                render: (item) => {
                    return (
                        <div className="flex">
                            {item.AutoCounter}
                            {!!item.Marked && (
                                <div className="ml-auto flex pl-5">
                                    <Tippy
                                        content={this.props.translate('field.Marked')}
                                    >
                                        <span className="relative">
                                             <FireIcon
                                                 className="relative z-12 h-5 w-5 cursor-pointer text-red-600 animate-pulse"/>
                                        </span>
                                    </Tippy>
                                </div>
                            )}
                        </div>
                    )
                }
            }),
            RefNumber: new Field('RefNumber', '', [], false, 'text'),
            Labels: new Field('Labels', '', ['empty'], false, 'custom', {
                render: (it) => !!it.ColorTag && <ResourceTableTags data={it.ColorTag}/>
            }),
            IsPayrollExpense: new Field('IsPayrollExpense', '', ['empty'], false, 'custom', {
                render: (it) => !!it.IsPayrollExpense &&
                    <Tippy content={<span>Payroll expense</span>}><UsersIcon className={'w-5 h-5'}/></Tippy>
            }),
            ExpenseTypeID: new Field('ExpenseTypeID', '', [], false, 'select'),
            Date: new Field('Date', '', [''], false, 'date'),
            BookDate: new Field('BookDate', '', [''], false, 'date', {hideDialog: true}),
            DueDate: new Field('DueDate', '', [''], false, 'date'),
            PayTo: new Field('PayTo', '', [''], false, 'custom', {
                render: (item) =>
                    <CellButton
                        onClick={() => item.OrganizationID ? this.handleToggleOrganizationInfoDialog(item) : this.handleToggleContactInfoDialog(item)}>
                        <div>
                            {item.ContactID ? item.Contact : item.Organization}
                        </div>
                    </CellButton>,
                label: this.props.type === 'expense' ? 'ChargeTo' : 'PayTo'
            }),
            Amount: new Field('Amount', '', [], false, 'money'),
            AmountTransferred: new Field('AmountTransferred', '', [''], false, 'money', {
                addTableHeaderClass: 'ml-auto',
                render: (item) => {
                    return <div
                        className={'flex justify-end w-full'}>{genericMoneyFormatter(item.Amount - item.AmountTransferred)}</div>
                }
            }),
            AmountAdvance: new Field('AmountAdvance', '', [''], false, 'money', {
                addTableHeaderClass: 'ml-auto',
                render: (item) => {
                    return <div className={'flex justify-end w-full'}>{genericMoneyFormatter(item.AmountAdvance)}</div>
                }
            }),
            AmountAdvanceTransferred: new Field('AmountAdvanceTransferred', '', [''], false, 'money', {
                addTableHeaderClass: 'ml-auto',
                render: (item) => {
                    return <div
                        className={'flex justify-end w-full'}>{genericMoneyFormatter(item.AmountAdvance - item.AmountAdvanceTransferred)}</div>
                }
            }),
            ExpenseStatusID: new Field('ExpenseStatusID', '', [''], false, 'custom', {
                render: (it) => renderExpenseStatusBadge(it)
            }),
            Office: new Field('Office', '', [], false, 'text'),
            ContactGroup: new Field('ContactGroup', '', [], false, 'text', {label: 'ExpenseGroupID'}),
            InternalNotes: new Field('InternalNotes', '', [], false, 'custom', {
                render: (item) => (
                    <div>{longTableColumn(item.InternalNotes)}</div>
                )
            }),
            IsApproved: new Field('IsApproved', '', [''], false, 'custom', {
                render: this.renderIsApproved
            }),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'datetimezone'),
            UpdatedByContactID: new Field('UpdatedByContactID', '', [], false, 'select-search')
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            ExpenseStatusID: new Field('ExpenseStatusID', '', [''], false, 'select', {}, {
                all: true,
                values: getLookup('ExpenseStatus'),
            }),
            StartDate: new Field('StartDate', moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {addContainerClass: 'col-span-full'}, {}),
            EndDate: new Field('EndDate', moment().endOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {addContainerClass: 'col-span-full'}, {}),
            DateType: new Field('DateType', 1, [''], false, 'button-group', {
                    data: {
                        1: this.props.translate('text.Date'),
                        2: this.props.translate('text.PostingDate'),
                        3: this.props.translate('text.DueDate')
                    },
                    labelType: 'stack',
                    addClass: 'w-full',
                    addButtonClass: 'uppercase font-semibold',
                    addContainerClass: 'col-span-full',
                    label: 'DateType_Date/PostingDate/DueDate'
                }
            ),
            ContactID: new Field('ContactID', '', [], false, 'select-search', {}, {isClearable: true}),
            OrganizationID: new Field('OrganizationID', '', [], false, 'select-search', {}, {isClearable: true}),
            OfficeID: new Field('OfficeID', '', [], false, 'select-search', {}, {
                isClearable: true
            }),
            ExpenseTypeID: new Field('ExpenseTypeID', '', [''], false, 'select', {}, {
                all: true,
                values: {
                    1: 'Bill',
                    2: 'Expense'
                }
            }),
            RecurringExpenseID: new Field('RecurringExpenseID', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            IsApproved: new Field('IsApproved', '', [], false, 'checkbox', {}, {}),
            AmountMoreThan: new Field('AmountMoreThan', '', [], false, 'float'),
            AmountLessThan: new Field('AmountLessThan', '', [], false, 'float'),
            AmountExact: new Field('AmountExact', '', [], false, 'float'),
            AdvanceMoreThan: new Field('AdvanceMoreThan', '', [], false, 'float'),
            AdvanceLessThan: new Field('AdvanceLessThan', '', [], false, 'float'),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                hideLabel: true,
                labelType: 'float'
            }, {menuPlacement: 'top'})
        }

    }

    getFieldTypes = () => {
        let fieldsCombined = Object.assign({}, this.getTableFields())

        fieldsCombined = Object.values(fieldsCombined).reduce((memo, it) => {
            memo[it.name] = it.type
            return memo
        }, {})

        fieldsCombined['AmountTransferred'] = 'money'
        fieldsCombined['Marked'] = 'checkbox'
        fieldsCombined['IsApproved'] = 'checkbox'
        fieldsCombined['IsDelayedPayment'] = 'checkbox'
        fieldsCombined['IsRevised'] = 'checkbox'
        fieldsCombined['HasReference'] = 'checkbox'

        return Object.assign({}, fieldsCombined)
    }

    getAdjustFields = () => {
        return {
            Date: new Field('Date', '', ['empty'], false, 'date'),
            Amount: new Field('Amount', '', ['empty'], false, 'float', {}),
            AccountID: new Field('AccountID', '', ['empty'], false, 'select-search', {}),
            Memo: new Field('Memo', '', [''], false, 'text'),
        }
    }

    getDetailsFields(item = {}) {
        const fieldTemplates = {
            Description: new Field("Description", '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
            OrganizationID: new Field('OrganizationID', '', [''], false, 'select-search', {addContainerClass: 'col-span-full'}),
            Date: new Field('Date', '', [''], false, 'date', {addContainerClass: 'col-span-3'}),
            OfficeID: new Field('OfficeID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-3'}),
            ContactGroupID: new Field('ContactGroupID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-3'}),
            Amount: new Field('Amount', '', [''], false, 'money', {addContainerClass: 'col-span-3'}),
            VendorCreditStatusID: new Field('VendorCreditStatusID', '', [''], false, 'select', {
                addContainerClass: 'col-span-3',
                hideDialog: true
            }, {
                values: {0: "Pending", 1: "Attached to bill", 2: "Cash payout"}
            }),

        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    getApplyCreditDateFields() {
        return {
            Date: new Field('Date', currentDate(), ['empty'], false, 'date', {addContainerClass: 'col-span-3'})
        }
    }

    /** UI Events (pagination, filters, sort)
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({fieldsApplyCreditDate: FieldsManager.updateField(this.state.fieldsApplyCreditDate, name, value)})
    }

    handleUpdateSort = (sortBy) => {
        let sort = (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        if (!sortBy) {
            sortBy = 'ExpenseID'
            sort = 'ASC'
        }

        this.setState({
            sortBy: sortBy,
            sort: sort
        }, () => {
            this.fetchData()
            this.saveFilters()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        queryFilterFields.StartDate.props.maxDate = ''
        queryFilterFields.EndDate.props.minDate = ''

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleDeleteDialog = (submit, item, restore = false) => {
        this.setState({
            confirmText: !restore ? this.props.translate('message.confirm_delete_expense', [item?.AutoCounter]) : this.props.translate('message.confirm_restore_generic') + ` Expense: ${item?.ExpenseID}`,
            deleteDialog: submit
        })
    }

    handleToggleApproveDialog = (submit) => {
        this.setState({
            confirmText: this.props.translate('text.ApproveEntry'),
            approveDialog: submit,
            showConfirm: !this.state.showConfirm
        })
    }

    toggleLimitBelowRequiredDialog = () => {
        this.setState({
            isLimitBelowRequiredDialogOpen: !this.state.isLimitBelowRequiredDialogOpen
        })
    }

    toggleAdjustExpense = (item = null) => {
        this.setState({
            selectedItem: item,
            isAdjustDialogVisible: !this.state.isAdjustDialogVisible
        })
    }

    toggleApplyCredit = (item = null) => {
        if (item?.OrganizationID) {
            this.fetchApplyCredits(item);
        } else this.props.dispatch(resetDialogResource());

        this.setState({
            selectedItem: item,
            isApplyCreditDialogVisible: !this.state.isApplyCreditDialogVisible,
            fieldsApplyCreditDate: this.getApplyCreditDateFields()
        })
    }

    ApplyCredit = (item = null) => {
        this.props.dispatch(createResource({
            user: LocalStorage.get('user'),
            params: {
                ExpenseID: this.state.selectedItem.ExpenseID,
                VendorCreditID: item.VendorCreditID,
                Date: moment(this.state.fieldsApplyCreditDate.Date.value).format("YYYY-MM-DD HH:mm:ss")
            },
            piggyQuery: this.getQuery(),
            piggyResource: this.getResourceName(),
            resource: Resources.ExpenseVendorCreditApply
        }))


        this.setState({
            selectedItem: item,
            isApplyCreditDialogVisible: !this.state.isApplyCreditDialogVisible,
            fieldsApplyCreditDate: this.getApplyCreditDateFields()
        })
    }

    handleToggleSendPaymentDialog = (item) => {
        this.setState({
            sendPaymentItem: item,
            isSendPaymentDialogVisible: !this.state.isSendPaymentDialogVisible
        })
    }

    handleToggleShowDetails = (item) => {
        this.setState({
            isDetailsDialogVisible: !this.state.isDetailsDialogVisible,
            selectedItem: item
        })
    }

    handleToggleOrganizationInfoDialog = (item = {}) => {
        this.setState({
            selectedOrganization: item,
            isOrganizationInfoDialogOpen: true
        })
    }

    handleToggleContactInfoDialog = (item = {}) => {
        this.setState({
            selectedContact: item,
            isContactInfoDialogOpen: true
        })
    }

    closeBatchActionDialog = () => {
        this.setState({batchActionDialogHideAnimation: true}, () => {
            setTimeout(() => {
                this.setState({batchActionDialog: false, batchActionDialogHideAnimation: false})
            })
        })
    }

    handleSelectRowClick = (item, event) => {
        const itemID = item.ExpenseID
        const data = getProp(this.props.resource.data, 'list', [])
        let selectedRows = cloneDeep(this.state.selectedRows)
        let selectedItems = this.state.selectedItems
        if (event?.nativeEvent?.shiftKey) {
            const firstSelectedItem = data.findIndex(it => selectedRows[it.ExpenseID])
            const lastSelectedItem = data.findIndex(it => it.ExpenseID === itemID)

            if (firstSelectedItem > -1 && lastSelectedItem > -1) {
                if (firstSelectedItem < lastSelectedItem) {
                    for (let i = firstSelectedItem; i < lastSelectedItem + 1; i++) {
                        Object.assign(selectedRows, {[data[i].ExpenseID]: data[i]})
                        selectedItems[data[i].ExpenseID] = data[i]
                    }
                } else {
                    for (let i = lastSelectedItem; i < firstSelectedItem + 1; i++) {
                        selectedItems[data[i].ExpenseID] = data[i]
                        Object.assign(selectedRows, {[data[i].ExpenseID]: data[i]})
                    }
                }
            }
        } else {
            if (selectedRows[itemID]) {
                delete selectedItems[itemID]
                delete selectedRows[itemID]
            } else {
                selectedItems[itemID] = data.find(it => it.ExpenseID === itemID)
                Object.assign(selectedRows, {[itemID]: item})
            }
        }

        this.setState({selectedRows, selectedItems})
    }

    handleSelectAllClick = (selectAll) => {
        const data = cloneDeep(getProp(this.props.resource.data, 'list', []))

        let selectedRows = cloneDeep(this.state.selectedRows)
        let selectedItems = this.state.selectedItems

        if (!selectAll) {
            Object.assign(selectedRows, data.reduce((memo, it) => {
                selectedItems[it.ExpenseID] = it
                memo[it.ExpenseID] = it
                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selectedRows)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it.ExpenseID.toString())) {
                    delete selectedItems[it.ExpenseID]
                    delete selectedRows[it.ExpenseID]
                }
            })
        }

        this.setState({selectedRows, selectedItems})
    }

    setSelectedRows = (selectedRows) => {
        this.setState({selectedRows})
    }

    handleFilterInputChange = (name, value) => {
        const queryFilterFields = FieldsManager.updateField(this.state.queryFilterFields, name, value)

        queryFilterFields.StartDate.props.maxDate = queryFilterFields.EndDate.value
        queryFilterFields.EndDate.props.minDate = queryFilterFields.StartDate.value

        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsModalOpen: !this.state.columnsModalOpen
        })
    }

    handleTogglePayMultipleBills = () => {
        this.setState({payMultipleBillsModalOpen: !this.state.payMultipleBillsModalOpen})
    }

    handleOpenInvoiceDialog = () => {
        this.setState({
            isInvoiceDialogVisible: true
        })
    }

    handleCloseInvoiceDialog = () => {
        this.setState({
            isInvoiceDialogVisible: false,
            dialogHideAnimation: false
        })
    }

    openCopyExpenseDialog = (item = null) => {
        this.setState({
            isExpenseCopyDialogVisible: true,
            selectedItem: item
        })
    }

    closeCopyExpenseDialog = () => {
        this.setState({
            isExpenseCopyDialogVisible: false,
            dialogHideAnimation: false
        })
    }

    handleToggleHistoryModal = (item = null) => {
        this.setState({
            historyModalOpen: !this.state.historyModalOpen,
            selectedItem: item
        })
    }

    handleFullScreenToggle = () => {
        this.setState({
            isTableFullScreen: !this.state.isTableFullScreen
        })
    }

    toggleIncomeExpenseDialog = (item = null) => {
        this.setState({
            incomeExpenseDialog: !this.state.incomeExpenseDialog,
            selectedItem: item
        })
    }

    /** Helpers
     ================================================================= */
    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getTableFields(), this.tablePageDefaults, this.props.translate)
    }

    editHidden = (item) => {
        const LockedData = getProp(this.props.resource.data, 'defaults.LockedDate', null)
        return !((!LockedData || (moment(LockedData).diff(item?.Date) < 0))
            && !item?.IsPayrollExpense
            && !item?.IsDelayedPayment
        )
    }

    deleteHidden = (item) => {
        const LockedData = getProp(this.props.resource.data, 'defaults.LockedDate', null)
        return !((!LockedData || (moment(LockedData).diff(item?.Date) < 0))
            && !item?.IsPayrollExpense
            && !item?.IsDelayedPayment
            && (!(item?.AmountTransferred > 0) || (item?.ExpenseTypeID != 1))
        )
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state,
            ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'input', 'showArchived'])
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    canSendPayment = (item) => {
        return item.ExpenseStatusID == 1 && !item.IsDelayedPayment
    }

    getDialogTypeForItem = (item) => {
        if (item?.TypeID == 1) {
            if (item?.SubTypeID == 2) {
                return 'receipt'
            } else {
                return 'invoice'
            }
        } else if (item?.TypeID == 2) {
            if (item?.SubTypeID == 2) {
                return 'expense'
            } else {
                return 'bill'
            }
        }

        return null
    }

    getResourceName = () => {
        return Resources.Expense
    }

    /** Render
     ================================================================= */

    renderIsApproved = (item) => {
        return (
            <div className={'pr-3'}>
                {!!item.IsApproved ?
                    <div className="pl-4">
                        <div className={'flex justify-center'}>
                            <CheckCircleIcon className="w-5 h-5 text-green-600"/>
                        </div>
                        <Tippy content={toFrontDateTimeFromUTC(item.ApprovedDateTime)}>
                            <div className={'text-center'}>{item.ApprovedByContact}</div>
                        </Tippy>
                    </div>
                    :
                    <div className="pl-4 flex justify-center">
                        <XMarkIcon className="w-5 h-5 text-tm-gray-400"/>
                    </div>
                }
            </div>
        )
    }

    render() {
        const {translate, resource, thirdResource, historyResource, dialogResource} = this.props
        const data = getProp(resource.data, 'list', [])
        const defaults = getProp(resource.data, 'defaults', [])
        const count = getProp(resource.data, 'count', 0)

        const contactCanApproveExpense = getProp(resource.data, 'Contact.CanApproveExpense', 0)
        const contactApprovalLimit = getProp(resource.data, 'Contact.MaxExpenseValue', 0)
        const lowLimitInfoMessage = `You are allowed to approve expenses up to $${contactApprovalLimit} only, please contact your manager.`

        const hasSelectedRows = !!Object.keys(this.state.selectedRows).length
        const totalAmount = genericMoneyFormatter(Object.values(this.state.selectedRows).reduce((memo, it) => memo + it.Amount, 0))
        const totalBalance =
            genericMoneyFormatter(Object.values(this.state.selectedRows).reduce((memo, it) => memo + (it.Amount - it.AmountTransferred), 0))

        const totalAdvance =
            genericMoneyFormatter(Object.values(this.state.selectedRows).reduce((memo, it) => memo + (it.AmountAdvance), 0))

        const selectedRowsContainExpense = Object.values(this.state.selectedRows).some((item) => item.ExpenseTypeID === 2)

        const secondData = getProp(this.props.secondResource, 'data', [])
        const dataApplyCredit = getProp(dialogResource.data, 'list', [])
        const isDialogLoading = getProp(dialogResource, 'isLoading', false)


        return (
            <React.Fragment>
                <div className="sm:flex mb-2">
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleClearFiltersClick}
                        translate={translate}
                    />

                    <div className="ml-auto flex sm:justify-start justify-end items-center">
                        <div className={'flex mr-4'}>
                            <Tippy content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcel}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                        <Button
                            hasPerm={checkPerm(Resources.Expense, CREATE_PERM)}
                            className={`btn btn-primary`}
                            onClick={this.handleOpenInvoiceDialog}
                        >
                            {translate('btn.create_new')}
                        </Button>
                    </div>
                </div>

                <TableCard
                    addClass={
                        classNames(
                            this.state.isTableFullScreen
                                ? 'fixed inset-1 -top-1 z-50'
                                : undefined
                        )}
                >
                    <TableFilters
                        hideLimit
                        handleInputChange={this.handleFilterInputChange}
                        filterFields={excludeFields(this.state.queryFilterFields, ['StartDate', 'EndDate', 'DateType'])}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                        forceDialog={true}
                        isTableFullScreen={this.state.isTableFullScreen}
                        isLoading={resource.isLoading}
                        onFullScreenToggle={this.handleFullScreenToggle}

                        customHtml={
                            <PayDateFilter
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                                updateQueryFields={(queryFieldsUpdate) => {
                                    this.setState({
                                        queryFilterFields: queryFieldsUpdate,
                                        offset: 0
                                    }, () => this.fetchData())
                                    this.saveFilters(queryFieldsUpdate)
                                }}
                                onQueryChange={this.handleFilterInputChange}
                                hasDateType={true}
                                isDateTypeClearable={true}
                            />
                        }

                        selects={{
                            RecurringExpenseID: {
                                api: 'api/' + Resources.ExpenseRecurring,
                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                searchMap: (item) => ({
                                    label: item.TemplateName,
                                    value: item.RecurringExpenseID
                                })
                            }
                        }}
                    />

                    <ResourceTable
                        data={data}
                        fields={this.getTableFields()}
                        verticalTableIsVisible={this.props.breakpoint.index <= 1}

                        translate={translate}
                        isLoading={resource.isLoading}

                        options={this.state.tableOptions}

                        limit={this.state.queryFilterFields.limit.value}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onRowClick={(item) => checkPerm(Resources.ExpenseExpense, READ_PERM) ? this.handleToggleShowDetails(item) : null}

                        actions={[
                            {
                                action: this.handleToggleShowDetails,
                                icon: EyeIcon,
                                hasPerm: checkPerm(Resources.ExpenseExpense, READ_PERM),
                                title: translate('text.show_details'),
                            },
                            {
                                action: this.toggleIncomeExpenseDialog,
                                icon: PencilIcon,
                                visible: (item) => !this.editHidden(item),
                                hasPerm: checkPerm(this.getResourceName(), UPDATE_PERM) && checkPerm(Resources.ExpenseExpense, READ_PERM),
                                title: translate('text.edit'),
                            },
                            {
                                action: this.handleToggleSendPaymentDialog,
                                icon: CreditCardIcon,
                                visible: (item) => this.canSendPayment(item),
                                hasPerm: checkPerm(Resources.ExpensePayment, CREATE_PERM),
                                title: translate('text.send_payment'),
                            },
                            {
                                action: this.toggleAdjustExpense,
                                icon: AdjustmentsHorizontalIcon,
                                visible: () => true,
                                hasPerm: checkPerm(Resources.ExpenseAdjust, UPDATE_PERM),
                                title: translate('text.edit_adjust_expense'),
                            },
                            {
                                action: this.toggleApplyCredit,
                                icon: TicketIcon,
                                visible: () => true,
                                hasPerm: checkPerm(Resources.ExpenseVendorCreditApply, CREATE_PERM),
                                title: translate('text.apply_credit'),
                            },
                            {
                                action: this.approveResource,
                                icon: CheckIcon,
                                visible: (item) => contactCanApproveExpense && !item.IsApproved,
                                hasPerm: checkPerm(Resources.ExpenseApprove, UPDATE_PERM),
                                title: translate('btn.approve'),
                            },
                            {
                                action: this.deleteResource,
                                icon: TrashIcon,
                                visible: (item) => !this.deleteHidden(item),
                                hasPerm: checkPerm(this.getResourceName(), DELETE_PERM),
                                title: translate('btn.delete'),
                            },
                            {
                                action: this.handleToggleHistoryModal,
                                customContent: () => <HourglassIcon className="w-5 h-5"/>,
                                hasPerm: checkPerm(Resources.ExpenseHistory, READ_PERM),
                                title: translate('text.history'),
                            },
                            {
                                action: this.openCopyExpenseDialog,
                                icon: DocumentDuplicateIcon,
                                hasPerm: checkPerm(this.getResourceName(), CREATE_PERM),
                                title: translate('btn.copy'),
                            }
                        ]}

                        onSelectRow={this.handleSelectRowClick}
                        selectedRows={this.state.selectedRows}
                        onSelectAllClick={this.handleSelectAllClick}
                        tableKey={'ExpenseID'}

                        saveTableOptions={this.setOptions}
                    />


                    <TableBulkActions
                        selectedRows={this.state.selectedRows}
                        options={this.state.tableOptions}
                        onSelectAllClick={this.handleSelectAllClick}
                        tableKey="ExpenseID"
                        fields={this.getTableFields()}
                        translate={translate}
                        setSelectedRows={this.setSelectedRows}
                        footerContent={
                            <div className="text-sm flex justify-between w-full px-4 py-1 h-8">
                                <div className="whitespace-nowrap">
                                    Total amount for selected entries:
                                    <span className="ml-2 font-bold text-primary-contrast">
                                        {totalAmount} (Balance {totalBalance}) - (Advances {totalAdvance})
                                    </span>
                                </div>
                            </div>
                        }
                    >
                        <div className="flex items-center h-8">
                            <div className={classNames('flex divide-x-2 divide-primary-shade pr-2')}>

                                {checkPerm(Resources.AccountingMark, UPDATE_PERM) && (
                                    <div className="flex items-center gap-x-1 pr-4">
                                        <Tippy content={translate('btn.set_as_marked')}>
                                            <button
                                                onClick={() => this.handleSetMarkedClick(true)}
                                                className="flex p-1 rounded-btn hover:bg-primary-shade"
                                            >
                                                <FireSolidIcon
                                                    className="w-5 h-5 text-primary-contrast"/>
                                            </button>
                                        </Tippy>

                                        <Tippy content={translate('btn.remove_from_marked')}>
                                            <button
                                                onClick={() => this.handleSetMarkedClick(false)}
                                                className="flex p-1 rounded-btn hover:bg-primary-shade"
                                            >
                                                <FireIcon
                                                    className="w-5 h-5 text-primary-contrast"/>
                                            </button>
                                        </Tippy>
                                    </div>
                                )}

                                {checkPerm(Resources.ExpensePayment, UPDATE_PERM) && (
                                    <div className="flex items-center gap-x-1 px-4">
                                        <Tippy content={translate('text.PayMultipleBills')}>
                                            <button
                                                onClick={() => this.setState({batchActionDialog: true})}
                                                className="flex p-1 rounded-btn hover:bg-primary-shade"
                                            >
                                                <CreditCardIcon
                                                    className="w-5 h-5 text-primary-contrast"/>
                                            </button>
                                        </Tippy>
                                    </div>
                                )}

                                {checkPerm(Resources.ExpenseExpense, READ_PERM) && (
                                    <Flags authorizedFlags={['InvoiceJobON']}
                                           renderOn={() => (
                                               <div className="flex items-center gap-x-1 px-4">
                                                   <Tippy content={translate('text.create_zip')}>
                                                        <span>
                                                            <button
                                                                onClick={this.onBulkDownloadExpenses}
                                                                className="flex p-1 rounded-btn text-primary-contrast hover:bg-primary-shade disabled:hover:bg-primary disabled:opacity-60"
                                                            >
                                                                <ArchiveBoxArrowDownIcon
                                                                    className="w-5 h-5 "/>
                                                            </button>
                                                        </span>
                                                   </Tippy>
                                               </div>
                                           )}
                                           renderOff={() => null}
                                    />
                                )}

                                {checkPerm(Resources.AccountingColor, UPDATE_PERM) && (
                                    <div className="pl-4 text-tm-gray-700">
                                        <TableTagManager
                                            rowKey={'ExpenseID'}
                                            id={'ExpenseIDs'}
                                            requestMethod={'POST'}
                                            InvoiceIDs={[]}
                                            btnIconClass="w-5 h-5"
                                            customIcon={<ChevronUpSolidIcon className="w-5 h-5"/>}
                                            selectedRows={this.state.selectedRows}
                                            resourceName={Resources.AccountingColor}
                                            piggyResourceName={this.getResourceName()}
                                            query={this.getQuery()}
                                            onAfterTagRows={() => {
                                                this.setState({selectedRows: {}})
                                            }}
                                            translate={translate}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                    </TableBulkActions>

                    {hasSelectedRows && false && (
                        <div className="px-0 py-1 border-y border-tm-gray-200  flex items-center">
                            <div className="mr-4 flex space-x-4 h-9">
                            </div>
                            <div className="text-sm flex justify-between w-full">
                                <div>
                                        <span
                                            className="font-bold text-tm-gray-700">{Object.keys(this.state.selectedRows).length}</span> entries
                                    selected
                                </div>

                                <div className="mr-4">
                                    Total amount for selected entries:
                                    <span className="ml-2 font-bold text-tm-gray-700">
                                        {totalAmount} (Balance {totalBalance}) - (Advances {totalAdvance})
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}

                    {/*Table footer*/}
                    <TableCardFooter
                        show={!(!data.length && !resource.isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={resource.isLoading || thirdResource.isLoading}
                            hideRowsPerPage={this.props.breakpoint.index <= 1}
                            handleQueryChange={
                                (name, value, currentPage) => name === 'offset'
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>

                    <NoRecords
                        show={(data.length === 0) && !resource.isLoading}
                        title={translate('text.no_records')}
                        addClass={'pt-16 pb-10'}
                    />
                </TableCard>

                <Modal
                    show={!!this.state.payMultipleBillsModalOpen}
                    onClose={this.handleTogglePayMultipleBills}
                    widthClass={'max-w-full'}
                >
                    {this.state.payMultipleBillsModalOpen && (
                        <PayMultipleBillsDialog
                            onRemoveFromSelected={this.handleSelectRowClick}
                            onClose={this.handleTogglePayMultipleBills}
                            translate={translate}
                            selectedItems={this.state.selectedRows}
                            onSubmit={(params) => {
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get('user'),
                                    params: Object.assign({}, {
                                        morePayments: params
                                    }),
                                    resource: Resources.ExpensePayment,
                                    piggyResource: this.getResourceName(),
                                    query: this.getQuery(),
                                    errorMessage: true, successMessage: translate('text.multiple_bills_paid')
                                }))
                                this.setState({
                                    selectedItems: {},
                                    selectedRows: {}
                                })
                                this.handleTogglePayMultipleBills()
                            }}
                        />
                    )}
                </Modal>

                {this.state.isInvoiceDialogVisible && (
                    <IncomeExpenseDialog
                        isExpenseDialogType={true}
                        onClose={this.handleCloseInvoiceDialog}
                        defaults={defaults}
                        query={this.getQuery()}
                        onSubmit={(params, resource, files, fileResource, notePayableFields) => {
                            if (!!notePayableFields) {
                                Object.assign(params, notePayableFields)
                            }

                            if (params) {
                                if (params.id) {
                                    this.props.dispatch(updateResource({
                                        user: LocalStorage.get('user'),
                                        query: this.getQuery(),
                                        params: params,
                                        resource: resource,
                                        file: files,
                                        fileResource: Resources.ExpenseDocuments,
                                        piggyResource: Resources.Expense,
                                        errorMessage: true, successMessage: translate('text.entry_updated')
                                    }))
                                } else {
                                    this.props.dispatch(createResource({
                                        user: LocalStorage.get('user'),
                                        query: this.getQuery(),
                                        params: params,
                                        resource: resource,
                                        file: files,
                                        fileResource: Resources.ExpenseDocuments,
                                        piggyResource: Resources.Expense,
                                        errorMessage: true, successMessage: translate('text.entry_created')
                                    }))
                                }
                                this.handleCloseInvoiceDialog()
                            }
                        }}
                        {...this.props}
                    />
                )}

                <TableOptionsDialog
                    show={this.state.columnsModalOpen}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />

                <ModalSaveResource
                    title={'Adjust expense'}
                    widthClass="max-w-md"
                    show={this.state.isAdjustDialogVisible}
                    gridClass="grid gap-4 grid-cols-1 p-6"
                    onClose={this.toggleAdjustExpense}
                    fields={this.getAdjustFields()}
                    onSubmit={(params) => {
                        if (params) {
                            params.ExpenseID = this.state.selectedItem.ExpenseID
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.ExpenseAdjust,
                                piggyResource: this.getResourceName(),
                                errorMessage: true, successMessage: translate('message.expense_adjusted')
                            }))
                            this.toggleAdjustExpense(false)
                        }
                    }}
                    metadata={{
                        AccountID: {
                            api: 'api/' + Resources.AccountsQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                value: item.AccountID,
                                label: item.AccountNumber + ' ' + item.AccountName,
                                metadata: item
                            })
                        }
                    }}
                    translate={this.props.translate}

                    htmlAfter={(fields) => {
                        const originalValue = this.state.selectedItem?.AmountTransferred ?? 0
                        let newValue = originalValue

                        let mod = 1
                        const Account = fields.AccountID?.value?.metadata
                        switch (Account?.MainAccountTypeID) {
                            case MAJOR_ACCOUNT_TYPE_LIABILITY:
                            case MAJOR_ACCOUNT_TYPE_EXPENSE:
                                mod = 1
                                break
                            default:
                                mod = -1
                        }
                        newValue += ((fields.Amount?.value ? numberWithCommasToBack(fields.Amount?.value) : 0) * mod)

                        return (
                            <div className="p-5 md border-t border-tm-gray-300">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <CurrencyDollarIcon className="h-6 w-6 text-tm-gray-500"/>
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            <dt className="text-sm font-medium text-tm-gray-700 truncate">
                                                <span>{translate('text.original_value')}: {genericMoneyFormatter(originalValue)}</span>
                                            </dt>
                                            <dd>
                                                <div className="text-lg font-medium text-tm-gray-900">
                                                    <span>{translate('text.new_value')} {genericMoneyFormatter(newValue)}</span>
                                                </div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                />

                <ModalDefault
                    show={this.state.isDetailsDialogVisible}
                    widthClass={'max-w-full'}
                    title={translate('text.ExpenseID')}

                    buttonDisabled={this.editHidden(this.state.selectedItem) || !checkPerm(Resources.ExpenseExpense, UPDATE_PERM)}
                    onButtonClick={() => {
                        this.handleToggleShowDetails();
                        this.toggleIncomeExpenseDialog(this.state.selectedItem)
                    }}
                    buttonLabel={translate('btn.edit')}
                    closeButtonLabel={translate('btn.Close')}
                    customHtmlLeft={(
                        <Button
                            onClick={() => !this.deleteHidden(this.state.selectedItem) && this.deleteResource(this.state.selectedItem)}
                            appearance="danger"
                            disabled={this.deleteHidden(this.state.selectedItem)}
                            hasPerm={checkPerm(this.getResourceName(), DELETE_PERM)}
                        >
                            {translate("btn.delete")}
                        </Button>
                    )}
                    onMiddleButtonClick={() => this.handleToggleSendPaymentDialog(this.state.selectedItem)}
                    middleButtonLabel={translate('text.send_payment')}
                    middleButtonDisabled={!checkPerm(Resources.ExpensePayment, CREATE_PERM) || !this.canSendPayment(secondData)}
                    onClose={() => this.handleToggleShowDetails()}
                >
                    <InvoiceExpenseDetailsDialog
                        resource={this.props.secondResource}
                        selectedItem={this.state.selectedItem}
                        dialogResource={this.props.dialogResource}
                        isLoading={this.props.secondResource.isLoading || this.props.resource.isLoading}
                        translate={translate}
                        dispatch={this.props.dispatch}
                        type="expense"
                        createCommentDispatchAction={createSecondResource}
                        deleteCommentDispatchAction={deleteSecondResource}
                        expenseID={this.state.selectedItem?.ExpenseID}
                        canDeletePayment={this.state.selectedItem?.ExpenseTypeID != 2}
                        showLoadReferenceLinks

                        onDocumentDownload={(item) => {
                            const fileExt = item?.AttachPath.split('.').pop()

                            this.props.dispatch(download({
                                user: LocalStorage.get('user'),
                                resource: Resources.ExpenseDocuments,
                                query: Object.assign({
                                    id: item.ExpenseID,
                                    format: fileExt,
                                    name: 'document_' + currentDate() + '.' + fileExt
                                })
                            }))
                        }}

                        onFetchData={() => {
                            if (this.state.selectedItem) {
                                this.fetchInvoiceDialogData()
                            }
                        }}

                        onDeletePayment={(item) => {
                            this.props.dispatch(deleteSecondResource({
                                user: LocalStorage.get('user'),
                                resource: Resources.ExpensePayment,
                                query: {
                                    SendPaymentID: item.SendPaymentID
                                },
                                errorMessage: true,
                                successMessage: translate('text.PaymentDeletedSuccessfully')
                            }))
                        }}

                    />
                </ModalDefault>

                {this.state.incomeExpenseDialog && (
                    <IncomeExpenseDialog
                        isInvoiceDialogType={this.state.selectedItem?.Type == 1}
                        isExpenseDialogType={this.state.selectedItem?.Type == 2}
                        onClose={this.toggleIncomeExpenseDialog}
                        query={this.getQuery()}
                        dialogType={this.getDialogTypeForItem(this.state.selectedItem)}
                        updateItem={this.state.selectedItem}
                        onSubmit={(params, resource, files, fileResource) => {
                            if (params) {
                                if (params.id) {
                                    this.props.dispatch(updateResource({
                                        user: LocalStorage.get('user'),
                                        query: this.getQuery(),
                                        params: params,
                                        resource: resource,
                                        file: files,
                                        fileResource: fileResource,
                                        piggyResource: this.getResourceName(),
                                        errorMessage: true, successMessage: translate('text.entry_updated')
                                    }))
                                } else {
                                    this.props.dispatch(createResource({
                                        user: LocalStorage.get('user'),
                                        query: this.getQuery(),
                                        params: params,
                                        resource: resource,
                                        file: files,
                                        fileResource: fileResource,
                                        piggyResource: this.getResourceName(),
                                        errorMessage: true, successMessage: translate('text.entry_created')
                                    }))
                                }
                                this.toggleIncomeExpenseDialog()
                            }
                        }}
                        {...this.props}
                    />
                )}


                {this.state.isExpenseCopyDialogVisible && (
                    <IncomeExpenseDialog
                        isInvoiceDialogType={this.state.selectedItem?.Type == 1}
                        isExpenseDialogType={this.state.selectedItem?.Type == 2}
                        onClose={this.closeCopyExpenseDialog}
                        query={this.getQuery()}
                        dialogType={this.getDialogTypeForItem(this.state.selectedItem)}
                        updateItem={this.state.selectedItem}
                        isCopy={true}
                        onSubmit={(params, resource, files, fileResource) => {
                            if (params) {
                                params.Items = params.Items.map(it => {
                                    delete it['ExpenseLineID']
                                    return it
                                })
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get('user'),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: resource,
                                    file: files,
                                    fileResource: fileResource,
                                    piggyResource: this.getResourceName(),
                                    errorMessage: true, successMessage: translate('text.entry_created')
                                }))
                            }
                            this.closeCopyExpenseDialog()
                        }
                        }
                        {...this.props}
                    />
                )}

                <PaymentDialog
                    show={this.state.isSendPaymentDialogVisible}
                    payment={'send'}
                    item={this.state.sendPaymentItem}
                    resourcePath={Resources.ExpensePayment}
                    piggyResourcePath={Resources.Expense}
                    defaults={defaults}
                    query={this.getQuery()}
                    onClose={() => this.handleToggleSendPaymentDialog()}
                    {...this.props}
                />

                <BulkPaymentDialog
                    payment={'send'}
                    show={this.state.batchActionDialog}
                    translate={translate}
                    HideModalAnimation={this.state.batchActionDialogHideAnimation}
                    onClose={this.closeBatchActionDialog}
                    selectedItems={Object.values(this.state.selectedRows).filter((item) => item.ExpenseTypeID !== 2)}
                    onSubmit={this.submitBulkPayment}
                    defaults={defaults}
                    itemKeyLabel={'ExpenseID'}
                    containsInvalidEntry={selectedRowsContainExpense}
                    {...this.props}
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.deleteDialog}
                    text={this.state.confirmText}
                    onClose={() => this.handleToggleDeleteDialog()}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.deleteDialog}
                />

                {this.state.isLimitBelowRequiredDialogOpen && (
                    <ModalConfirm
                        title={translate('text.low_limit')}
                        type={'info'}
                        show={this.state.isLimitBelowRequiredDialogOpen}
                        text={lowLimitInfoMessage}
                        textClassName={'text-sm text-tm-gray-900'}
                        onClose={() => this.toggleLimitBelowRequiredDialog()}
                        buttonLabel={translate('btn.confirm')}
                        onConfirm={() => this.toggleLimitBelowRequiredDialog()}
                    />
                )}

                {(this.state.isOrganizationInfoDialogOpen &&
                    <OrganizationInfoDialog
                        show={this.state.isOrganizationInfoDialogOpen}
                        translate={translate}
                        organizationID={this.state.selectedOrganization?.OrganizationID}
                        handleClose={() => this.setState({
                            isOrganizationInfoDialogOpen: false
                        })}
                    />
                )}

                {(this.state.isContactInfoDialogOpen &&
                    <ContactInfoDialog
                        show={this.state.isContactInfoDialogOpen}
                        translate={translate}
                        contactID={this.state.selectedContact?.ContactID}
                        handleClose={() => this.setState({
                            isContactInfoDialogOpen: false
                        })}
                    />
                )}

                <Modal
                    show={this.state.historyModalOpen}
                    widthClass={'max-w-full'}
                    onClose={() => this.setState({historyModalOpen: false})}
                    translate={translate}
                >
                    {this.state.historyModalOpen && (
                        <HistoryModal
                            onClose={() => this.setState({historyModalOpen: false})}
                            translate={translate}
                            title={translate('text.' + Resources.ExpenseHistory)}
                            tableFields={DEFAULT_HISTORY_TABLE_FIELDS(translate, this.getFieldTypes())}
                            filterFields={DEFAULT_HISTORY_TABLE_FILTER_FIELDS()}
                            onFetchData={(query) => {
                                this.props.dispatch(getHistoryResource({
                                    user: LocalStorage.get('user'),
                                    query: Object.assign({}, query, {
                                        id: this.state.selectedItem.ExpenseID
                                    }),
                                    resource: Resources.ExpenseHistory
                                }))
                            }}
                            data={getProp(historyResource, 'data.list', [])}
                            count={getProp(historyResource, 'data.count', 0)}
                            isLoading={getProp(historyResource, 'isLoading', false)}
                            showUserAvatar

                            onRowClick={(item) => {
                                this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))
                            }}
                            fieldTypes={this.getFieldTypes()}
                        />
                    )}
                </Modal>

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.showConfirm}
                    text={this.state.confirmText}
                    onClose={() => this.handleToggleApproveDialog()}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.approveDialog}
                />

                <ModalDefault
                    show={this.state.isApplyCreditDialogVisible}
                    widthClass={'max-w-6xl'}
                    title={translate('text.apply_credit')}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.toggleApplyCredit()}
                >
                    <div>
                        <div className={"grid grid-cols-12 m-2"}>
                            <FieldContainer
                                item={this.state.fieldsApplyCreditDate.Date}
                                translate={translate}
                            >
                                <FieldDate
                                    {...this.state.fieldsApplyCreditDate.Date}
                                    addClass={'form-control text-center col-span-2'}
                                    onChange={this.handleInputChange}
                                    showTimeSelect={false}
                                />
                            </FieldContainer>
                        </div>

                        <ResourceTable
                            data={dataApplyCredit}
                            commonTable={true}
                            maxHeightClass={'max-h-[calc(100vh-32rem)]'}
                            fields={this.getDetailsFields()}

                            translate={translate}
                            isLoading={isDialogLoading}

                            limit={(dataApplyCredit?.length ?? 10)}

                            actions={[
                                {
                                    action: this.ApplyCredit,
                                    icon: TicketIcon,
                                    visible: () => !!this.state.fieldsApplyCreditDate.Date.value,
                                    hasPerm: checkPerm(Resources.ExpenseVendorCreditApply, CREATE_PERM),
                                    title: translate('btn.apply'),
                                }
                            ]}
                        />

                        <NoRecordsTable
                            show={(dataApplyCredit.length === 0) && !isDialogLoading}
                            canCreate={false}
                            title={translate('text.no_matching_records')}
                        />
                    </div>
                </ModalDefault>

            </React.Fragment>
        )
    }
}

export default connect(state => state)(ExpenseTab)
