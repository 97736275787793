import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {
    getDefaultContactGroup,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON, getDefaultUserOfficeValues,
    groupListBySCAC,
    openInNewTab,
    returnOffsetAndPagination,
    saveTableColumns,
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import EFSIntegrationImportDialog from './efs-import-integration-dialog'
import {showGlobalModal, showMapDialog} from '../../../data/actions/ui'
import {
    DocumentArrowDownIcon,
    DocumentArrowUpIcon,
    EyeIcon,
    MapPinIcon,
    PencilIcon,
    TrashIcon
} from '@heroicons/react/24/outline'
import Tippy from '@tippyjs/react'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DELETE_PERM, MAJOR_ACCOUNT_TYPE_ASSET, MAJOR_ACCOUNT_TYPE_EXPENSE, MAJOR_ACCOUNT_TYPE_INCOME,
    READ_PERM,
    UPDATE_PERM
} from '../../../util/util-constants'
import {checkPerm, classNames, getLookup, getProp, mergeDeep} from '../../../common/util/util-helpers'
import {cloneDeep} from '../../../common/util/util-vanilla'
import {createSecondResource, getSecondResource} from "../../../data/actions/secondResource";
import FuelIntegrationSettingsDialog from "./fuel-integration-settings-dialog";
import Cog6ToothIcon from "@heroicons/react/24/outline/esm/Cog6ToothIcon";
import {numberWithCommas} from "../../../util/util-formaters";
import PresentationChartLineIcon from "@heroicons/react/24/outline/PresentationChartLineIcon";
import banknotesIcon from "@heroicons/react/24/outline/esm/BanknotesIcon";
import moment from "moment/moment";
import funnelIcon from "@heroicons/react/24/outline/esm/FunnelIcon";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import Layout from "../../../common/components/layout";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ResourceTable from "../../../common/components/resource-table";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalDefault from "../../../common/components/modal/modal-default";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import Modal from "../../../common/components/modal";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import ToolTipTableCell from "../../../common/components/resource-table/table-components/tooltip-table-cell";
import WidgetStats from "../../../common/components/widgets/widget-stats";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import ButtonIntegration from "../../../common/components/button/button-integration";
import {LoaderSmall} from "../../../common/components/loader";
import {download} from "../../../data/actions/download";
import {currentDate} from "../../../common/util/util-dates";
import ImportExcelDataModal from "../../../common/components/modal/import-excel-data-modal";
import {resetPreviewDocumentResource} from "../../../data/actions/previewDocumentResource";

class FuelPurchaseView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props?.location?.pathname?.substring(1) ?? ''

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: '',

            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),

            createUpdateDialog: false,
            createUpdateDialogHideAnimation: false,
            confirmDialog: false,
            confirmDialogHideAnimation: false,
            areStatsVisible: LocalStorage.has(this.pageSettingsName) ? JSON.parse(LocalStorage.get(this.pageSettingsName) ?? {})?.areStatsVisible : true,
            breakpoint: {}
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, 'StartDate', moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'))
        })
        this.fetchData()

    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    fetchFuelIntegrationSettingsData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: Resources.FuelIntegrationSettings,
            query: {
                limit: 10,
                offset: 0
            }
        }))
    }

    archiveResource = (item = null) => {
        this.setState({text: `${this.props.translate('message.are_you_sure_delete_fuel_purchase')}`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: {
                            FuelPurchaseID: item?.FuelPurchaseID
                        },
                        piggyQuery: this.getQuery(),
                        errorMessage: true,
                        successMessage: `Fuel purchase info has been deleted`,
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    onExportExcelData = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({format: 'EXCEL', name: 'fuel_purchase_' + currentDate() + '.xlsx'}, this.getQuery())
        }))
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleShowCreateUpdateDialog = (selectedItem = null) => {
        this.setState({selectedItem: selectedItem, createUpdateDialog: true})
    }

    handleLocationMapView = (item) => {
        this.props.dispatch(showMapDialog(item))
    }

    handleCloseCreateUpdateDialog = () => {
        setTimeout(() => {
            this.setState({
                createUpdateDialogHideAnimation: false,
                createUpdateDialog: false,
                selectedItem: null,
                prefilled: null
            })
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFilter = () => {
        this.setState({
            queryFilterFields: this.getQueryFilterFields(),
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate);
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleFuelIntegrationSettingsModal = () => {
        this.setState({
            fuelIntegrationSettingsModalOpen: !this.state.fuelIntegrationSettingsModalOpen
        }, () => {
            this.state.fuelIntegrationSettingsModalOpen && this.fetchFuelIntegrationSettingsData()
        })
    }

    handleToggleEFSIntegrationModal = () => {
        this.setState({
            EFSIntegrationDialog: !this.state.EFSIntegrationDialog
        })
    }

    handleSetLocations = (fields) => {
        this.setState({
            autocomplete: fields
        })
    }

    handleStatsToggleClick = () => {
        this.setState({areStatsVisible: !this.state.areStatsVisible}, () => {
            let pageSettings = {}

            if (LocalStorage.has(this.pageSettingsName)) {
                pageSettings = JSON.parse(LocalStorage.get(this.pageSettingsName))
            }

            Object.assign(pageSettings, {areStatsVisible: this.state.areStatsVisible})
            LocalStorage.set(this.pageSettingsName, JSON.stringify(pageSettings))
        })
    }

    handleToggleImportExcelDataModal = () => {
        this.setState({
            importExcelDataModalOpen: !this.state.importExcelDataModalOpen
        }, () => {
            if (!this.state.importExcelDataModalOpen) {
                this.props.dispatch(resetPreviewDocumentResource())
            }
        })
    }

    /** Fields/Data Definitions
     ================================================================= */
    getCreateUpdateFields = (item = null) => {
        if (!!item) {
            item.LocationName = item.Location;
        }
        const fieldTemplates = {
            CountryID: new Field('CountryID', '1', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),
            GooglePlaces: new Field('GooglePlaces', '', [], false, 'google-locations', {
                addContainerClass: "col-span-full"
            }),
            LocationName: new Field('LocationName', '', ['empty'], false, 'text', {
                label: 'Name',
                addContainerClass: 'col-span-full'
            }),
            AddressName: new Field('AddressName', '', ['empty'], false, 'text', {addContainerClass: 'col-span-full'}),
            CityName: new Field('CityName', '', ['empty'], false, 'text', {addContainerClass: 'col-span-4'}),
            StateID: new Field('StateID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-4'}),
            PostalCode: new Field('PostalCode', '', ['empty'], false, 'text', {addContainerClass: 'col-span-4'}),

            DriverID: new Field('DriverID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}),
            TruckID: new Field('TruckID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}),
            TrailerID: new Field('TrailerID', '', [''], false, 'select-search', {addContainerClass: 'col-span-full'}),
            Date: new Field('Date', '', ['empty'], false, 'datetimezone', {addContainerClass: 'col-span-4'}),
            Amount: new Field('Amount', '', ['float'], false, 'float', {
                label: '$Amount',
                addContainerClass: 'col-span-full'
            }),
            Fuel: new Field('Fuel', '', ['float'], false, 'float', {
                label: 'FuelInGallons',
                addContainerClass: 'col-span-full'
            }),
            Notes: new Field('Notes', '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    getFields = () => {
        return {
            Location: new Field('Location', '', ['empty'], false, 'text', {
                label: 'Name',
            }),
            Driver: new Field('Driver', '', [''], false, 'custom', {
                render: (item) => (
                    <ToolTipTableCell
                        placement={'right'}
                        cellValue={item.Driver}
                        tooltips={[
                            {
                                id: checkPerm(Resources.DriverInfo, READ_PERM) ? item.ContactID : null,
                                content: this.props.translate('btn.quick_view'),
                                onClick: () => this.props.dispatch(showGlobalModal('ViewDriverCard', item.DriverID)),
                                icon: EyeIcon
                            },
                            {
                                id: checkPerm(Resources.DriverInfo, UPDATE_PERM) ? item.DriverID : null,
                                content: this.props.translate('btn.edit'),
                                onClick: () => this.props.history.push(`/drivers/info/${item.DriverID}`),
                                icon: PencilIcon,
                            },
                        ]}
                    />
                )
            }),
            TruckID: new Field('TruckID', '', [], false, 'custom', {
                render: (item) => {
                    return <ToolTipTableCell
                        placement={"right"}
                        cellValue={item.Truck}
                        tooltips={[
                            {
                                id: checkPerm(Resources.TrucksInfo, READ_PERM) ? item.TruckID : null,
                                content: this.props.translate("btn.quick_view"),
                                onClick: () => this.props.dispatch(showGlobalModal('ViewTruckCard', item.TruckID)),
                                icon: EyeIcon
                            },
                            {
                                id: checkPerm(Resources.TrucksInfo, UPDATE_PERM) ? item.TruckID : null,
                                content: this.props.translate("btn.edit"),
                                onClick: () => this.props.history.push(`/trucks/info/${item.TruckID}`),
                                icon: PencilIcon
                            },
                        ]}
                    />
                }
            }),
            TrailerID: new Field('TrailerID', '', [], false, 'custom', {
                render: (item) => {
                    return (
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                checkPerm(Resources.Trailer, READ_PERM) && openInNewTab(`/trailers/info/${item.TrailerID}`);
                            }}
                            className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                        >
                            {item.Trailer}
                        </button>
                    )
                }
            }),
            Amount: new Field('Amount', '', ['empty'], false, 'money'),
            Fuel: new Field('Fuel', '', [''], false, 'custom', {
                render: (item) => (
                    <>{item.Fuel}</>
                )
            }),
            Date: new Field('Date', '', [''], false, 'datetimezone'),
            AddressName: new Field('AddressName', '', [''], false, ''),
            CityName: new Field('CityName', '', [''], false, ''),
            StateID: new Field('StateID', '', ['empty'], false, 'select', {}),
            PostalCode: new Field('PostalCode', '', ['empty'], false, 'text', {}),

            CreateUpdateDate: new Field('CreateUpdateDate', '', [''], false, 'datetimezone'),
            Source: new Field('Source', '', [''], false, ''),
            Notes: new Field('Notes', '', [''], false, '')
        }
    }


    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            DriverID: new Field('DriverID', '', [''], false, 'select-search', {}, {isClearable: true}),
            TruckID: new Field('TruckID', '', [''], false, 'select-search', {}, {isClearable: true}),
            StartDate: new Field('StartDate', '', [''], false, 'date', {}, {isClearable: true}),
            EndDate: new Field('EndDate', '', [''], false, 'date', {}, {isClearable: true}),
            limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            })
        }
    }

    /** Helpers
     ================================================================= */

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'query', 'offset'])
    }

    getResource = () => {
        return Resources.Fuel
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)
        const defaults = getProp(resource.data, 'defaults', [])
        const TotalAmount = getProp(resource.data, 'TotalAmount', 0)
        const TotalFuel = getProp(resource.data, 'TotalFuel', 0)

        const dataIntegrations = getProp(resource, 'data.integrations', {})
        const hasIntegrations = dataIntegrations && Object.keys(dataIntegrations).some(it => !!dataIntegrations[it].hasIntegration)


        const stats = [
            {
                id: 1,
                name: translate('text.TotalAmount'),
                icon: banknotesIcon,
                value: numberWithCommas(TotalAmount),
                valuePrefix: '$',
                decimalCount: 2
            },
            {
                id: 2,
                name: translate('text.TotalFuel'),
                icon: funnelIcon,
                value: (TotalFuel),
                valueSuffix: ' gal',
                decimalCount: 2
            }
        ]
        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.fuel_purchase')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.handleShowCreateUpdateDialog()}
                        hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    ><Tippy
                        content={translate(this.state.areStatsVisible ? 'text.hide_stats' : 'text.show_stats')}
                        delay={[400, 0]}
                        trigger="mouseenter"
                    >
                        <button
                            className={
                                classNames(
                                    this.state.areStatsVisible ? 'text-primary border-primary' : undefined,
                                    'btn btn-header'
                                )
                            }
                            onClick={this.handleStatsToggleClick}
                        >
                            <PresentationChartLineIcon
                                className="w-5 h-5"
                            />
                        </button>
                    </Tippy>

                        <ButtonIntegration
                            id="integration-btn"
                            hasIntegration={hasIntegrations}
                            history={this.props.history}
                            hasPerm={checkPerm(Resources.FuelIntegrationPreviews, CREATE_PERM)}
                            onClick={() => {
                                dataIntegrations && Object.keys(dataIntegrations).map(it => {
                                    this.setState({
                                        EFSIntegrationDialog: true,
                                        resourceName: dataIntegrations[it].resource
                                    })})
                            }}
                            integrationName={"EFS | Relay"}
                        >
                            {translate('text.import_data')}
                        </ButtonIntegration>
                    </PageHeader>

                    {this.state.areStatsVisible && (
                        <div className="relative z-40">
                            <div className="grid grid-cols-1 gap-5 sm:grid-cols-3 lg:grid-cols-3">
                                <WidgetStats
                                    stats={stats}
                                />
                            </div>
                        </div>
                    )}

                    <div className="flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFilter}
                            translate={translate}
                        />

                        <div className="ml-auto flex">
                            {hasIntegrations && (
                                <Tippy content={translate('text.EFSSEttings')}>
                                    <button
                                        className="btn-icon"
                                        onClick={this.handleToggleFuelIntegrationSettingsModal}
                                    >
                                        <Cog6ToothIcon className="w-5 h-5"/>
                                    </button>
                                </Tippy>
                            )}

                            <Tippy content={translate('text.ExportDataToExcel')}>
                                <button
                                    className="btn-icon relative"
                                    onClick={this.onExportExcelData}
                                >
                                    {!!getProp(this.props, 'download.isLoading', false) && (
                                        <LoaderSmall addClass={'w-3 h-3'}/>
                                    )}
                                    {!getProp(this.props, 'download.isLoading', false) && (
                                        <DocumentArrowDownIcon className="w-5 h-5"/>
                                    )}
                                </button>
                            </Tippy>

                            {checkPerm(Resources.FuelExcelImport, CREATE_PERM) && checkPerm(this.getResource(), CREATE_PERM) &&(
                                <Tippy content={translate('text.ImportDataFromExcel')}>
                                    <button
                                        className="btn-icon"
                                        onClick={this.handleToggleImportExcelDataModal}
                                    >
                                        <DocumentArrowUpIcon className="w-5 h-5"/>
                                    </button>
                                </Tippy>
                            )}

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    <TableCard addClass={'relative'}>
                        <TableFilters
                            addClass="relative z-30"
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            isLoading={resource.isLoading}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            actions={[
                                {
                                    action: this.handleLocationMapView,
                                    icon: MapPinIcon, // make this a function
                                    visible: (item) => (item.Latitude && item.Longitude),
                                    label: false, // make this a function
                                    title: translate('text.map'),
                                    disabled: false,
                                    class: false,
                                    iconClass: false
                                },
                                {
                                    action: this.handleShowCreateUpdateDialog,
                                    icon: PencilIcon, // make this a function
                                    visible: (it) => (it.Source == 'Manual'),
                                    hasPerm: () => checkPerm(this.getResource(), UPDATE_PERM),
                                    label: false, // make this a function
                                    title: translate('btn.edit'),
                                    disabled: false,
                                    class: false,
                                    iconClass: false
                                },
                                {
                                    action: this.archiveResource,
                                    icon: TrashIcon, // make this a function
                                    hasPerm: () => checkPerm(this.getResource(), DELETE_PERM),
                                    label: false, // make this a function
                                    title: translate('btn.delete'),
                                    disabled: false,
                                    class: false,
                                    iconClass: false
                                },
                            ]}
                        />

                        {/*Table footer*/}
                        <TableCardFooter
                            show={!(!data.length && !resource.isLoading)}
                        >
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                            title={'No matching records found'}
                            text={'Create a new fuel purchase'}
                            btnLabel="Create fuel purchase"
                            onBtnClick={() => this.handleShowCreateUpdateDialog()}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />
                    </TableCard>

                    <ModalDefault
                        show={this.state.EFSIntegrationDialog}
                        widthClass={'max-w-5xl'}
                        title={translate('modal_heading.efs_integration_details')}

                        closeButtonLabel={translate('btn.Cancel')}
                        onClose={this.handleToggleEFSIntegrationModal}
                    >
                        <EFSIntegrationImportDialog
                            integrations={getProp(resource, 'data.integrations', {})}
                            translate={translate}
                            resourceName={this.state.resourceName}
                            thirdResource={this.props.thirdResource}
                            resource={this.props.integrationResource}
                            dispatch={this.props.dispatch}
                            isLoading={this.props.integrationResource.isLoading || this.props.resource.isLoading}
                            defaults={defaults}
                        />
                    </ModalDefault>


                    <ModalSaveResource
                        title={translate("text.add_fuel")}
                        gridColsClass={"grid-cols-12"}
                        show={this.state.createUpdateDialog}
                        keyid={'FuelPurchaseID'}
                        value={this.state.selectedItem}
                        translate={translate}
                        fields={this.getCreateUpdateFields(this.state.selectedItem)}
                        onClose={this.handleCloseCreateUpdateDialog}
                        onSubmit={(params) => {
                            params.query = this.getQuery();
                            params.Location = params.LocationName;
                            params.resource = this.getResource();
                            delete params.LocationName;

                            if (this.state.selectedItem) {
                                params.FuelPurchaseID = this.state.selectedItem.FuelPurchaseID
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get('user'),
                                    params: params,
                                    query: this.getQuery(),
                                    resource: this.getResource(),
                                    piggyResource: this.getResource(),
                                    errorMessage: true,
                                    successMessage: `Fuel purchase info has been updated`,
                                }))
                            } else {
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get('user'),
                                    params: params,
                                    query: this.getQuery(),
                                    resource: this.getResource(),
                                    piggyResource: this.getResource(),
                                    errorMessage: true,
                                    successMessage: `Fuel purchase info has been created`,
                                }))
                            }
                            this.handleCloseCreateUpdateDialog()
                        }}
                        resourceName={this.getResource()}
                        metadata={{
                            DriverID: {
                                api: 'api/' + Resources.DriversQuick,
                                query: {},
                                searchMap: (item) => ({
                                    value: item.DriverID,
                                    label: item.FirstName + ' ' + item.LastName
                                })
                            },
                            TruckID: {
                                api: 'api/' + Resources.TrucksQuick,
                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                customizeList: (list) => {
                                    return groupListBySCAC(list, 'Truck')
                                }
                            },
                            TrailerID: {
                                api: 'api/' + Resources.TrailersQuick,
                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                customizeList: (list) => {
                                    return groupListBySCAC(list, 'Trailer')
                                }
                            },
                            StateID: getLookup('State')
                        }}
                        handleInputChange={(fields, name, value) => {
                            let fld = fields;
                            if ("GooglePlaces" === name) {
                                for (const [key, val] of Object.entries(value)) {
                                    if (key in fld) {
                                        fld = FieldsManager.updateField(fld, key, val)
                                    }
                                }
                            } else {
                                fld = FieldsManager.updateField(fields, name, value);
                                if (name === 'CountryID' && value == 1) {
                                    fld.StateID.validate = ['empty']
                                    fld.StateID.type = 'select'
                                }
                                if (name === 'CountryID' && value != 1) {
                                    fld.StateID.validate = []
                                    fld.StateID.value = null
                                    fld.StateID.type = 'hidden'
                                }
                            }
                            return fld
                        }}
                        {...this.props}
                    />

                    <Modal
                        show={!!this.state.fuelIntegrationSettingsModalOpen}
                        widthClass={'max-w-7xl w-screen'}
                        closeButtonLabel={translate('btn.close')}
                        onClose={this.handleToggleFuelIntegrationSettingsModal}

                        translate={translate}
                    >
                        <FuelIntegrationSettingsDialog
                            onClose={this.handleToggleFuelIntegrationSettingsModal}
                            translate={translate}
                            resource={this.props.secondResource}
                            isLoading={this.props.secondResource.isLoading}
                            onSubmit={(items) => {
                                this.props.dispatch(createSecondResource({
                                    user: LocalStorage.get("user"),
                                    resource: Resources.FuelIntegrationSettings,
                                    params: {
                                        Items: items
                                    },
                                    request: 'PUT',
                                    piggyResource: Resources.FuelIntegrationSettings
                                }))
                            }}
                        />
                    </Modal>

                    <ModalConfirm
                        title={'Confirm'}
                        show={!!this.state.confirmDialog}
                        text={this.state.text}
                        onClose={() => this.setState({confirmDialog: false})}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={this.state.confirmModal}
                    />

                    <Modal
                        show={!!this.state.importExcelDataModalOpen}
                        widthClass={'max-w-7xl w-screen'}
                        onClose={this.handleToggleImportExcelDataModal}
                        translate={translate}
                    >
                        <ImportExcelDataModal
                            onClose={this.handleToggleImportExcelDataModal}
                            translate={translate}
                            modalTitle={translate("text.ImportFuelPurchase")}
                            previewDocumentResourceName={Resources.FuelExcelPreview}
                            onUploadData={(data, additionalFields) => {
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get("user"),
                                    resource: Resources.FuelExcelImport,
                                    params: {
                                        Items: data,
                                        ...additionalFields
                                    },
                                    piggyResource: this.getResource(),
                                    query: this.getQuery()
                                }))

                                this.handleToggleImportExcelDataModal()
                            }}
                            previewHasError={getProp(this.props, 'previewDocumentResource.error', false)}
                            isPreviewLoading={getProp(this.props, 'previewDocumentResource.isLoading', false)}
                            previewData={getProp(this.props, 'previewDocumentResource.data', [])}
                            additionalFields={{
                                CreateBill: new Field('CreateBill', '', [], false, 'checkbox', {
                                    addContainerClass: 'col-span-12'
                                }),
                                OrganizationID: new Field('OrganizationID', '', ['empty'], false, 'select-search', {
                                    addContainerClass: 'col-span-6'
                                }),
                                RefNumber: new Field('RefNumber', '', ['empty'], false, 'text', {
                                    addContainerClass: 'col-span-6'
                                }),
                                OfficeID: new Field('OfficeID', getDefaultUserOfficeValues(), ['empty'], false, 'select-search', {
                                    addContainerClass: 'col-span-6'
                                }),
                                ContactGroupID: new Field(
                                    'ContactGroupID',
                                    getDefaultContactGroup(),
                                    ['empty'],
                                    false,
                                    'select-search',
                                    {
                                        addContainerClass: 'col-span-6'
                                    },
                                ),
                                Date: new Field('Date', '', ['empty'], false, 'date', {addContainerClass: 'col-span-4'}),
                                BookDate: new Field('BookDate', '', ['empty'], false, 'date', {addContainerClass: 'col-span-4'}),
                                DueDate: new Field('DueDate', '', ['empty'], false, 'date', {addContainerClass: 'col-span-4'}),
                                ExpensesAccountID: new Field('ExpensesAccountID', '', ['empty'], false, 'select-search', {
                                    addContainerClass: 'col-span-6'
                                }),
                                AdvancesAccountID: new Field('AdvancesAccountID', '', ['empty'], false, 'select-search', {
                                    addContainerClass: 'col-span-6'
                                }),
                                AdditionalAmount: new Field('AdditionalAmount', '0', ['float'], false, 'money', {addContainerClass: 'col-span-4'}),
                            }}
                            fields={{
                                DriverID: new Field('DriverID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}),
                                TruckID: new Field('TruckID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}),
                                StateID: new Field('StateID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-4'}),
                                CityName: new Field('CityName', '', ['empty'], false, 'text', {addContainerClass: 'col-span-4'}),
                                PostalCode: new Field('PostalCode', '', ['empty'], false, 'text', {addContainerClass: 'col-span-4'}),
                                Fuel: new Field('Fuel', '', ['float'], false, 'float', {
                                    label: 'FuelInGallons',
                                }),
                                Amount: new Field('Amount', '', ['float'], false, 'float', {
                                    label: '$Amount',
                                }),
                                Date: new Field('Date', '', ['empty'], false, 'datetimezone', {addContainerClass: 'col-span-4'}),
                            }}
                            selects={{
                                OrganizationID: {
                                    api: 'api/' + Resources.OrganizationsQuick,
                                    query: {},
                                    searchMap: (item) => ({
                                        value: item.OrganizationID,
                                        label: item.LegalName + " City: " + item.CityName + " State: " + getLookup('State')[item.StateID] + " Zip: " + item.PostalCode
                                    })
                                },
                                DriverID: {
                                    api: 'api/' + Resources.DriversQuick,
                                    query: {},
                                    searchMap: (item) => ({
                                        value: item.DriverID,
                                        label: item.FirstName + ' ' + item.LastName
                                    })
                                },
                                TruckID: {
                                    api: 'api/' + Resources.TrucksQuick,
                                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                    customizeList: (list) => {
                                        return groupListBySCAC(list, 'Truck')
                                    }
                                },
                                StateID: getLookup("State", "StateID", "StateAbbreviation"),
                                AdvancesAccountID: {
                                    api: 'api/' + Resources.AccountsQuick,
                                    query: {
                                        MainAccountTypeID: MAJOR_ACCOUNT_TYPE_ASSET,
                                        ...DEFAULT_METADATA_SELECT_SEARCH_QUERY()
                                    },
                                    searchMap: (item) => ({
                                        label: item.AccountNumber + ' ' + item.AccountName,
                                        value: item.AccountID
                                    })
                                },
                                ExpensesAccountID: {
                                    api: 'api/' + Resources.AccountsQuick,
                                    query: {
                                        MainAccountTypeID: MAJOR_ACCOUNT_TYPE_EXPENSE,
                                        ...DEFAULT_METADATA_SELECT_SEARCH_QUERY()
                                    },
                                    searchMap: (item) => ({
                                        label: item.AccountNumber + ' ' + item.AccountName,
                                        value: item.AccountID
                                    })
                                },
                            }}
                        />
                    </Modal>

                    <TableOptionsDialog
                        show={this.state.isColumnsDialogVisible}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.handleSetTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(FuelPurchaseView)
